import loadable from '@loadable/component'
import React, { FunctionComponent } from 'react'
import { EasySoftwareSideCardCustomType } from 'Shared/components/EasySoftwareSideCard/EasySoftwareSideCardCustom'
import { EasySoftwareSideCardNavigationType } from 'Shared/components/EasySoftwareSideCard/EasySoftwareSideCardNavigation'
import { EasySoftwareSideCardNewsletterType } from 'Shared/components/EasySoftwareSideCard/EasySoftwareSideCardNewsletter'
import { EasySoftwareSideCardTrialType } from 'Shared/components/EasySoftwareSideCard/EasySoftwareSideCardTrial'
import { WidgetEasySoftwareBenefitsType } from 'Shared/components/widgets/WidgetEasySoftwareBenefits'
import { WidgetEasySoftwareCardsType } from 'Shared/components/widgets/WidgetEasySoftwareCards'
import { WidgetEasySoftwareCaseStudiesType } from 'Shared/components/widgets/WidgetEasySoftwareCaseStudies'
import { WidgetEasySoftwareEmbeddedCodeContainerType } from 'Shared/components/widgets/WidgetEasySoftwareEmbeddedCodeContainer'
import { WidgetEasySoftwareFeaturesType } from 'Shared/components/widgets/WidgetEasySoftwareFeatures'
import { WidgetEasySoftwareIconBarType } from 'Shared/components/widgets/WidgetEasySoftwareIconBar'
import { WidgetEasySoftwareModulesType } from 'Shared/components/widgets/WidgetEasySoftwareModules'
import { WidgetEasySoftwareNavigatorType } from 'Shared/components/widgets/WidgetEasySoftwareNavigator'
import { WidgetEasySoftwareReviewsType } from 'Shared/components/widgets/WidgetEasySoftwareReviews'
import { WidgetEasySoftwareSearchBannerType } from 'Shared/components/widgets/WidgetEasySoftwareSearchBanner'
import { WidgetEasySoftwareSolutionsType } from 'Shared/components/widgets/WidgetEasySoftwareSolutions'
import { WidgetEasySoftwareTableType } from 'Shared/components/widgets/WidgetEasySoftwareTable'
import { WidgetEasySoftwareTestimonialsType } from 'Shared/components/widgets/WidgetEasySoftwareTestimonials'
import { WidgetEasySoftwareTimelineType } from 'Shared/components/widgets/WidgetEasySoftwareTimeline'
import { WidgetEasySoftwareVideoBannerType } from 'Shared/components/widgets/WidgetEasySoftwareVideoBanner'
import { WidgetPuxGalleryType } from 'Shared/components/widgets/WidgetPuxGallery'
import { WidgetPuxGalleryWrapperType } from 'Shared/components/widgets/WidgetPuxGalleryWrapper'
import { WidgetPuxImageContentType } from 'Shared/components/widgets/WidgetPuxImageContent'
import { WidgetPuxNumbersContainerType } from 'Shared/components/widgets/WidgetPuxNumbersContainer'
import { WidgetPuxRepeaterType } from 'Shared/components/widgets/WidgetPuxRepeater'
import WidgetSection, {
  WidgetPuxSectionType,
} from 'Shared/components/widgets/WidgetPuxSection'
import WidgetPuxTopImage, {
  WidgetPuxTopImageType,
} from 'Shared/components/widgets/WidgetPuxTopImage'
import { WidgetPuxVideoContainerType } from 'Shared/components/widgets/WidgetPuxVideoContainer'
import { WidgetPuxWysiwygType } from 'Shared/components/widgets/WidgetPuxWysiwyg'

const WidgetPuxImageContent = loadable(
  () => import(`Shared/components/widgets/WidgetPuxImageContent`)
)
const WidgetEasySoftwareCaseStudies = loadable(
  () => import(`Shared/components/widgets/WidgetEasySoftwareCaseStudies`)
)
const EasySoftwareSideCardCustom = loadable(
  () =>
    import(`Shared/components/EasySoftwareSideCard/EasySoftwareSideCardCustom`)
)
const EasySoftwareSideCardNavigation = loadable(
  () =>
    import(
      `Shared/components/EasySoftwareSideCard/EasySoftwareSideCardNavigation`
    )
)
const EasySoftwareSideCardNewsletter = loadable(
  () =>
    import(
      `Shared/components/EasySoftwareSideCard/EasySoftwareSideCardNewsletter`
    )
)
const EasySoftwareSideCardTrial = loadable(
  () =>
    import(`Shared/components/EasySoftwareSideCard/EasySoftwareSideCardTrial`)
)
const WidgetEasySoftwareBenefits = loadable(
  () => import(`Shared/components/widgets/WidgetEasySoftwareBenefits`)
)
const WidgetEasySoftwareCards = loadable(
  () => import(`Shared/components/widgets/WidgetEasySoftwareCards`)
)
const WidgetEasySoftwareEmbeddedCodeContainer = loadable(
  () =>
    import(`Shared/components/widgets/WidgetEasySoftwareEmbeddedCodeContainer`)
)
const WidgetEasySoftwareFeatures = loadable(
  () => import(`Shared/components/widgets/WidgetEasySoftwareFeatures`)
)
const WidgetEasySoftwareIconBar = loadable(
  () => import(`Shared/components/widgets/WidgetEasySoftwareIconBar`)
)
const WidgetEasySoftwareModules = loadable(
  () => import(`Shared/components/widgets/WidgetEasySoftwareModules`)
)
const WidgetEasySoftwareNavigator = loadable(
  () => import(`Shared/components/widgets/WidgetEasySoftwareNavigator`)
)
const WidgetEasySoftwareReviews = loadable(
  () => import(`Shared/components/widgets/WidgetEasySoftwareReviews`)
)
const WidgetEasySoftwareSolutions = loadable(
  () => import(`Shared/components/widgets/WidgetEasySoftwareSolutions`)
)
const WidgetEasySoftwareTable = loadable(
  () => import(`Shared/components/widgets/WidgetEasySoftwareTable`)
)
const WidgetEasySoftwareTestimonials = loadable(
  () => import(`Shared/components/widgets/WidgetEasySoftwareTestimonials`)
)
const WidgetEasySoftwareTimeline = loadable(
  () => import(`Shared/components/widgets/WidgetEasySoftwareTimeline`)
)
const WidgetEasySoftwareVideoBanner = loadable(
  () => import(`Shared/components/widgets/WidgetEasySoftwareVideoBanner`)
)
const WidgetPuxGallery = loadable(
  () => import(`Shared/components/widgets/WidgetPuxGallery`)
)
const WidgetPuxGalleryWrapper = loadable(
  () => import(`Shared/components/widgets/WidgetPuxGalleryWrapper`)
)
const WidgetPuxNumbersContainer = loadable(
  () => import(`Shared/components/widgets/WidgetPuxNumbersContainer`)
)
const WidgetPuxRepeater = loadable(
  () => import(`Shared/components/widgets/WidgetPuxRepeater`)
)
const WidgetPuxVideoContainer = loadable(
  () => import(`Shared/components/widgets/WidgetPuxVideoContainer`)
)
const WidgetPuxWysiwyg = loadable(
  () => import(`Shared/components/widgets/WidgetPuxWysiwyg`)
)
const WidgetEasySoftwareSearchBanner = loadable(
  () => import(`Shared/components/widgets/WidgetEasySoftwareSearchBanner`)
)

export type WidgetData =
  | WidgetPuxSectionType
  | WidgetPuxRepeaterType
  | WidgetPuxWysiwygType
  | WidgetEasySoftwareBenefitsType
  | WidgetEasySoftwareCardsType
  | WidgetEasySoftwareCaseStudiesType
  | WidgetEasySoftwareFeaturesType
  | WidgetEasySoftwareTableType
  | WidgetEasySoftwareNavigatorType
  | WidgetPuxGalleryType
  | WidgetPuxGalleryWrapperType
  | WidgetEasySoftwareReviewsType
  | WidgetEasySoftwareSolutionsType
  | WidgetPuxImageContentType
  | WidgetPuxNumbersContainerType
  | WidgetPuxTopImageType
  | WidgetPuxVideoContainerType
  | EasySoftwareSideCardNewsletterType
  | EasySoftwareSideCardCustomType
  | EasySoftwareSideCardTrialType
  | EasySoftwareSideCardNavigationType
  | WidgetEasySoftwareIconBarType
  | WidgetEasySoftwareEmbeddedCodeContainerType
  | WidgetEasySoftwareTimelineType
  | WidgetEasySoftwareModulesType
  | WidgetEasySoftwareTestimonialsType
  | WidgetEasySoftwareVideoBannerType
  | WidgetEasySoftwareSearchBannerType

export interface WidgetPageContextData {
  [widgetZone: string]: [WidgetData]
}

export interface WidgetMetadata {
  alignment: string
  size: number
}

export interface WidgetProperitesPart {
  widgetPropertiesPartID: string
  widgetPropertiesPartMarginBottom: string
  widgetPropertiesPartMarginTop: string
  widgetPropertiesPartPaddingBottom: string
  widgetPropertiesPartPaddingTop: string
  widgetPropertiesPartMobileOrder: string
}

export interface WidgetAnimationPart {
  widgetAnimationPartIsEnabled: boolean
  widgetAnimationPartType: string
}

export interface WidgetBase {
  contentItemId?: string
  metadata: WidgetMetadata
  puxWidgetProperties: WidgetProperitesPart
  puxWidgetAnimation: WidgetAnimationPart
}

export interface WidgetBuilderProps {
  widgetBuilderData: [WidgetData]
}

const buildWidget = (widgetData: WidgetData) => {
  if (!widgetData.contentItemId) {
    console.log(`denied by widgetbuilder`, widgetData.contentType)
    return
  }
  switch (widgetData.contentType) {
    case `PuxSection`:
      return <WidgetSection key={widgetData.contentItemId} {...widgetData} />

    case `PuxSimpleRepeater`:
      return (
        <WidgetPuxRepeater {...widgetData} key={widgetData.contentItemId} />
      )

    case `PuxWysiwyg`:
      return <WidgetPuxWysiwyg {...widgetData} key={widgetData.contentItemId} />

    case `EasySoftwareBenefits`:
      console.log(
        `🚀 ~ file: WidgetBuilder.tsx ~ line 209 ~ buildWidget ~ widgetData`,
        widgetData
      )
      return (
        <WidgetEasySoftwareBenefits
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareCards`:
      return (
        <WidgetEasySoftwareCards
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareCaseStudies`:
      return (
        <WidgetEasySoftwareCaseStudies
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareFeatures`:
      return (
        <WidgetEasySoftwareFeatures
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareTable`:
      return (
        <WidgetEasySoftwareTable
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareNavigator`:
      return (
        <WidgetEasySoftwareNavigator
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `PuxGallery`:
      return <WidgetPuxGallery {...widgetData} key={widgetData.contentItemId} />

    case `PuxGalleryWrapper`:
      return (
        <WidgetPuxGalleryWrapper
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareReviews`:
      return (
        <WidgetEasySoftwareReviews
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareSolutions`:
      return (
        <WidgetEasySoftwareSolutions
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `PuxNumbersContainer`:
      return (
        <WidgetPuxNumbersContainer
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `PuxImageContent`:
      return (
        <WidgetPuxImageContent {...widgetData} key={widgetData.contentItemId} />
      )

    case `PuxTopImage`:
      return (
        <WidgetPuxTopImage {...widgetData} key={widgetData.contentItemId} />
      )

    case `PuxVideoContainer`:
      return (
        <WidgetPuxVideoContainer
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareSideCardNewsletter`:
      return (
        <EasySoftwareSideCardNewsletter
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareSideCardTrial`:
      return (
        <EasySoftwareSideCardTrial
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareSideCardCustom`:
      return (
        <EasySoftwareSideCardCustom
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareSideCardNavigation`:
      return (
        <EasySoftwareSideCardNavigation
          {...widgetData}
          type='widget'
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareIconBar`:
      return (
        <WidgetEasySoftwareIconBar
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareEmbeddedCodeContainer`:
      return (
        <WidgetEasySoftwareEmbeddedCodeContainer
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareTimeline`:
      return (
        <WidgetEasySoftwareTimeline
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareModules`:
      return (
        <WidgetEasySoftwareModules
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareTestimonials`:
      return (
        <WidgetEasySoftwareTestimonials
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareVideoBanner`:
      return (
        <WidgetEasySoftwareVideoBanner
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )

    case `EasySoftwareSearchBanner`:
      return (
        <WidgetEasySoftwareSearchBanner
          {...widgetData}
          key={widgetData.contentItemId}
        />
      )
  }
}

const WidgetBuilder: FunctionComponent<WidgetBuilderProps> = (props) => {
  const widgetBuilderData = props.widgetBuilderData ?? []
  return (
    <>
      {widgetBuilderData.map((widgetData: WidgetData) =>
        buildWidget(widgetData)
      )}
    </>
  )
}

export default WidgetBuilder
